var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("iframe", {
      staticStyle: {
        height: "100vh",
        width: "100%",
        border: "0px solid",
        "margin-bottom": "-9px"
      },
      attrs: { src: _vm.iFrameURL }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }